import React, { useState } from "react";
import {Toppage,Summitpage} from '../ui-components';
// import {Undernavbar} from './ui-components';
import {Undernavbar} from './refined-components';
import {Archivepage} from './refined-components';
function TabControler(props) {
  const [activetab, setActivetab] = useState('1')
    return(
      <>
      <Undernavbar activetab={activetab} overrides={{
          "TOP":{
            fontSize:"88px",
            onClick:()=>{
              setActivetab('1')
            }
          },
          "SUMMIT":{
            fontSize:"88px",
            onClick:()=>{
              setActivetab('3')
            }
          },
          "ARCHIVE":{
            fontSize:"88px",
            onClick:()=>{
              setActivetab('2')
            }           
          },
          "Frame 420":{
            padding:"39px 68px 39px 68px"
          }
        }}></Undernavbar>
        {activetab === '1' &&
          <Toppage overrides={{
            "\u30ED\u30B4 1":{
              src: `${process.env.PUBLIC_URL}/ov2l_logo.png`,
              className:"clickable",
              onClick:()=>{window.open("https://discord.gg/6RmsHfQctf")}
            },
            "Button":{
              color:"#242424",
              backgroundColor:"#747474",
              onClick:()=>{window.open("https://www.youtube.com/channel/UChxZJBk4O1raggOmajFEKpQ/videos")},
            },
            "Join NOW!!":{
              width:"200px"
            },
            "OV2L\u306F\u58F0\u8CEA\u5909\u63DB(\u4E3B\u306B\u6A5F\u68B0\u5B66\u7FD2\u3084DL\u306A\u3069)\u306B\u95A2\u3059\u308B\u7814\u7A76\u3092\u884C\u308F\u308C\u3066\u3044\u308B\u65B9(\u8DA3\u5473\u30FB\u4ED5\u4E8B\u306F\u4E0D\u554F) \u540C\u58EB\u306E\u4EA4\u6D41\u306E\u5834\u3068\u3057\u3066\u548C\u6C17\u3042\u3044\u3042\u3044\u3068\u610F\u898B\u4EA4\u63DB\u3092\u3057\u3066\u3044\u304FDiscord\u30B3\u30DF\u30E5\u30CB\u30C6\u30A3\u3067\u3059\u3002 \u6708\u3054\u3068\u306E\u8AD6\u6587\u8F2A\u8AAD\u4F1A\u3084\u5E74\u3054\u3068\u306E\u30B5\u30DF\u30C3\u30C8\u3067\u65E5\u3005\u306E\u63A2\u6C42\u306E\u7D50\u679C\u3092\u767A\u8868\u3057\u3042\u3063\u3066\u3044\u307E\u3059\u3002":{
              fontSize:"20px"
            }
          }}></Toppage >
        }
        {activetab === '2' && 
          <Archivepage ></Archivepage>
        }
        {activetab === '3' &&
          <Summitpage overrides={{
            "Button34562726":{
              color:"#242424",
              backgroundColor:"#747474",
              onClick:()=>{window.open("https://docs.google.com/forms/d/e/1FAIpQLSdEcxLOFVWY96FOZNPPajHfeKx1gMjuudQi0ks6h-NbMXGzqA/viewform?usp=sf_link")}
            },
            "Button34562722":{
              color:"#242424",
              backgroundColor:"#747474",
              onClick:()=>{window.open("https://www.youtube.com/watch?v=bIoF--LtNJk&list=PL6q9s3S2J0lEjOTKjXk98anZZoFsQpEVA")}
            },
            "DEEP DaY(2\u65E5\u76EE\u30FB1/29(\u65E5))":{
              children:"Deep Day(2日目・1/29(日))"
            },
            "Light DaY(1\u65E5\u76EE\u30FB1/28(\u571F))":{
              children:"Light Day(1日目・1/28(土))"
            }
          }}></Summitpage>
        }
        
      </>
    );

}

export default TabControler;