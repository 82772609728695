/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Undernavbar(props) {
  const { overrides, ...rest } = props;
  const activetab = rest.activetab
  return (
    
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="#242424"
      {...rest}
      {...getOverrideProps(overrides, "Undernavbar")}
    >
      <p>{activetab}</p>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 421")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 2")}
        >
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            border="4px SOLID rgba(255,101,47,1)"
            padding="39px 23px 39px 23px"
            backgroundColor={activetab === '2' ? "rgba(255,101,47,1)" :"rgba(36,36,36,1)"}
            {...getOverrideProps(overrides, "Frame 419")}
          >
            <Text
              fontFamily="Julius Sans One"
              fontSize="96px"
              fontWeight="400"
              color={activetab === '2' ? "#242424" :"rgba(255,101,47,1)"}
              lineHeight="104.73599243164062px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ARCHIVE"
              {...getOverrideProps(overrides, "ARCHIVE")}
            ></Text>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            border="4px SOLID rgba(255,228,0,1)"
            padding="39px 128px 39px 128px"
            backgroundColor={activetab === '1' ? "#FFE400" :"rgba(36,36,36,1)"}
            {...getOverrideProps(overrides, "Frame 420")}
            className={activetab === '1' ? "active_top_box" :""}
          >
            <Text
              fontFamily="Julius Sans One"
              fontSize="96px"
              fontWeight="400"
              color={activetab === '1' ? "#242424" :"rgba(255,228,0,1)"}
              lineHeight="104.73599243164062px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="TOP"
              {...getOverrideProps(overrides, "TOP")}
              className={activetab === '1' ? "active_top_text" :""}
            ></Text>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            border="4px SOLID rgba(97,1,219,1)"
            padding="13px 12px 13px 12px"
            backgroundColor={activetab === '3' ? "rgba(97,1,219,1)" :"#242424"}
            {...getOverrideProps(overrides, "Frame 418")}
          >
            <Text
              fontFamily="Julius Sans One"
              fontSize="48px"
              fontWeight="400"
              color={activetab === '3' ? "#242424" :"rgba(97,1,219,1)"}
              lineHeight="52.36799621582031px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="2023/1/28-29"
              {...getOverrideProps(overrides, "2023/1/28-29")}
            ></Text>
            <Text
              fontFamily="Julius Sans One"
              fontSize="96px"
              fontWeight="400"
              color={activetab === '3' ? "#242424" :"rgba(97,1,219,1)"}
              lineHeight="104.73599243164062px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="448px"
              height="114px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="SUMMIT"
              {...getOverrideProps(overrides, "SUMMIT")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
