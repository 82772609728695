/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Archivepage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="51px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(36,36,36,1)"
      {...rest}
      {...getOverrideProps(overrides, "Archivepage")}
    >
      <Text
        fontFamily="Julius Sans One"
        fontSize="96px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="104.73599243164062px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="489px"
        height="106px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ARCHIVE"
        {...getOverrideProps(overrides, "ARCHIVE")}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="21.81999969482422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="過去の論文輪読会で紹介された論文と紹介者の一覧です"
        {...getOverrideProps(
          overrides,
          "\u904E\u53BB\u306E\u8AD6\u6587\u8F2A\u8AAD\u4F1A\u3067\u7D39\u4ECB\u3055\u308C\u305F\u8AD6\u6587\u3068\u7D39\u4ECB\u8005\u306E\u4E00\u89A7\u3067\u3059"
        )}
      ></Text>
      <Text
        fontFamily="Judson"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="23px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="CycleGAN-VC3 : tosakaさん&#xA;VAEとVQ-VAEについて0から勉強する会 : 天王洲アイルさん&#xA;ベイズ推論について勉強する会 : 天王洲アイルさん&#xA;NoiseVC: 天王洲アイルさん&#xA;VITS: kaffelunさん&#xA;StarGANv2-VC : tosakaさん&#xA;wav2vec2.0: kaffelunさん&#xA;UnitNet-Based Hybrid Speech Synthesis: 天王洲アイルさん&#xA;Glow-TTS: kaffelunさん&#xA;EfficientTTS: toskaさん&#xA;MaskCycleGAN-VC: 天王洲アイルさん"
        {...getOverrideProps(
          overrides,
          "CycleGAN-VC3 : tosaka\u3055\u3093 VAE\u3068VQ-VAE\u306B\u3064\u3044\u30660\u304B\u3089\u52C9\u5F37\u3059\u308B\u4F1A : \u5929\u738B\u6D32\u30A2\u30A4\u30EB\u3055\u3093 \u30D9\u30A4\u30BA\u63A8\u8AD6\u306B\u3064\u3044\u3066\u52C9\u5F37\u3059\u308B\u4F1A : \u5929\u738B\u6D32\u30A2\u30A4\u30EB\u3055\u3093 NoiseVC: \u5929\u738B\u6D32\u30A2\u30A4\u30EB\u3055\u3093 VITS: kaffelun\u3055\u3093 StarGANv2-VC : tosaka\u3055\u3093 wav2vec2.0: kaffelun\u3055\u3093 UnitNet-Based Hybrid Speech Synthesis: \u5929\u738B\u6D32\u30A2\u30A4\u30EB\u3055\u3093 Glow-TTS: kaffelun\u3055\u3093 EfficientTTS: toska\u3055\u3093 MsskCycleGAN-VC: \u5929\u738B\u6D32\u30A2\u30A4\u30EB\u3055\u3093"
        )}
      ></Text>
    </Flex>
  );
}
