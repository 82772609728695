/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function Summitpage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(36,36,36,1)"
      {...rest}
      {...getOverrideProps(overrides, "Summitpage")}
    >
      <Text
        fontFamily="Julius Sans One"
        fontSize="96px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="104.73599243164062px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="489px"
        height="106px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="SUMMIT"
        {...getOverrideProps(overrides, "SUMMIT")}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="21.81999969482422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="OV2L Evolving Summit 2023 を2023年1月28日~29日の二日間で開催します。&#xA;第二回のSummitになる今回は、入門者向けのLight Dayと上級者向けのDeep Dayに分けて開催します。"
        {...getOverrideProps(
          overrides,
          "OV2L Evolving Summit 2023 \u30922023\u5E741\u670828\u65E5~29\u65E5\u306E\u4E8C\u65E5\u9593\u3067\u958B\u50AC\u3057\u307E\u3059\u3002 \u7B2C\u4E8C\u56DE\u306ESummit\u306B\u306A\u308B\u4ECA\u56DE\u306F\u3001\u5165\u9580\u8005\u5411\u3051\u306ELight Day\u3068\u4E0A\u7D1A\u8005\u5411\u3051\u306EDeep Day\u306B\u5206\u3051\u3066\u958B\u50AC\u3057\u307E\u3059\u3002"
        )}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="48px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="52.36799621582031px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="656px"
        height="106px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Light DaY(1日目・1/28(土))"
        {...getOverrideProps(
          overrides,
          "Light DaY(1\u65E5\u76EE\u30FB1/28(\u571F))"
        )}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="21.81999969482422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ソフトウェアのおすすめの使い方やご自身の開発されたツールの紹介など、&#xA;機械学習の知見がなくても楽しめるものや&#xA;機械学習入門者向けの勉強会セッションなど予定しています。"
        {...getOverrideProps(
          overrides,
          "\u30BD\u30D5\u30C8\u30A6\u30A7\u30A2\u306E\u304A\u3059\u3059\u3081\u306E\u4F7F\u3044\u65B9\u3084\u3054\u81EA\u8EAB\u306E\u958B\u767A\u3055\u308C\u305F\u30C4\u30FC\u30EB\u306E\u7D39\u4ECB\u306A\u3069\u3001 \u6A5F\u68B0\u5B66\u7FD2\u306E\u77E5\u898B\u304C\u306A\u304F\u3066\u3082\u697D\u3057\u3081\u308B\u3082\u306E\u3084 \u6A5F\u68B0\u5B66\u7FD2\u5165\u9580\u8005\u5411\u3051\u306E\u52C9\u5F37\u4F1A\u30BB\u30C3\u30B7\u30E7\u30F3\u306A\u3069\u4E88\u5B9A\u3057\u3066\u3044\u307E\u3059\u3002"
        )}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="21.81999969482422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="680px"
        height="80px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="登壇者募集中！"
        {...getOverrideProps(
          overrides,
          "\u767B\u58C7\u8005\u52DF\u96C6\u4E2D\uFF0134562732"
        )}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="48px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="52.36799621582031px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="699px"
        height="106px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children=" DEEP DaY(2日目・1/29(日))"
        {...getOverrideProps(
          overrides,
          "DEEP DaY(2\u65E5\u76EE\u30FB1/29(\u65E5))"
        )}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="21.81999969482422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="論文紹介や現在開発中の機能についてのプレゼン、新しいツールの発表など&#xA;機械学習の知見を持つ人同士のディスカッションを目的とした&#xA;セッションなどを予定しています。"
        {...getOverrideProps(
          overrides,
          "\u8AD6\u6587\u7D39\u4ECB\u3084\u73FE\u5728\u958B\u767A\u4E2D\u306E\u6A5F\u80FD\u306B\u3064\u3044\u3066\u306E\u30D7\u30EC\u30BC\u30F3\u3001\u65B0\u3057\u3044\u30C4\u30FC\u30EB\u306E\u767A\u8868\u306A\u3069 \u6A5F\u68B0\u5B66\u7FD2\u306E\u77E5\u898B\u3092\u6301\u3064\u4EBA\u540C\u58EB\u306E\u30C7\u30A3\u30B9\u30AB\u30C3\u30B7\u30E7\u30F3\u3092\u76EE\u7684\u3068\u3057\u305F \u30BB\u30C3\u30B7\u30E7\u30F3\u306A\u3069\u3092\u4E88\u5B9A\u3057\u3066\u3044\u307E\u3059\u3002"
        )}
      ></Text>
      <Text
        fontFamily="Julius Sans One"
        fontSize="20px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="21.81999969482422px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="680px"
        height="80px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="登壇者募集中！"
        {...getOverrideProps(
          overrides,
          "\u767B\u58C7\u8005\u52DF\u96C6\u4E2D\uFF0134562734"
        )}
      ></Text>
      <Button
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="5px"
        padding="8px 16px 8px 16px"
        size="default"
        isDisabled={false}
        variation="default"
        children="登壇者として登録する"
        {...getOverrideProps(overrides, "Button34562726")}
      ></Button>
      <Button
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="5px"
        padding="8px 16px 8px 16px"
        size="default"
        isDisabled={false}
        variation="default"
        children="前回のOV2L Evolving Summit"
        {...getOverrideProps(overrides, "Button34562722")}
      ></Button>
    </Flex>
  );
}
