/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Toppage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="11px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(36,36,36,1)"
      {...rest}
      {...getOverrideProps(overrides, "Toppage")}
    >
      <Text
        fontFamily="Julius Sans One"
        fontSize="300px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="327.29998779296875px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="952px"
        height="422px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="OV2L"
        {...getOverrideProps(overrides, "OV2L")}
      ></Text>
      <Text
        fontFamily="Jaldi"
        fontSize="15px"
        fontWeight="400"
        color="rgba(116,116,116,1)"
        lineHeight="25.34912109375px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="1101px"
        height="252px"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="OV2Lは声質変換(主に機械学習やDLなど)に関する研究を行われている方(趣味・仕事は不問)&#xA;同士の交流の場として和気あいあいと意見交換をしていくDiscordコミュニティです。&#xA;月ごとの論文輪読会や年ごとのサミットで日々の探求の結果を発表しあっています。"
        {...getOverrideProps(
          overrides,
          "OV2L\u306F\u58F0\u8CEA\u5909\u63DB(\u4E3B\u306B\u6A5F\u68B0\u5B66\u7FD2\u3084DL\u306A\u3069)\u306B\u95A2\u3059\u308B\u7814\u7A76\u3092\u884C\u308F\u308C\u3066\u3044\u308B\u65B9(\u8DA3\u5473\u30FB\u4ED5\u4E8B\u306F\u4E0D\u554F) \u540C\u58EB\u306E\u4EA4\u6D41\u306E\u5834\u3068\u3057\u3066\u548C\u6C17\u3042\u3044\u3042\u3044\u3068\u610F\u898B\u4EA4\u63DB\u3092\u3057\u3066\u3044\u304FDiscord\u30B3\u30DF\u30E5\u30CB\u30C6\u30A3\u3067\u3059\u3002 \u6708\u3054\u3068\u306E\u8AD6\u6587\u8F2A\u8AAD\u4F1A\u3084\u5E74\u3054\u3068\u306E\u30B5\u30DF\u30C3\u30C8\u3067\u65E5\u3005\u306E\u63A2\u6C42\u306E\u7D50\u679C\u3092\u767A\u8868\u3057\u3042\u3063\u3066\u3044\u307E\u3059\u3002"
        )}
      ></Text>
      <Flex
        padding="0px 0px 0px 0px"
        width="289.22px"
        height="74px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Group 1")}
      >
        <Image
          width="178px"
          height="74px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "\u30ED\u30B4 1")}
        ></Image>
        <Text
          fontFamily="Jaldi"
          fontSize="24px"
          fontWeight="400"
          color="rgba(116,116,116,1)"
          lineHeight="40.55859375px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="16.56px"
          left="185px"
          transformOrigin="top left"
          transform="rotate(-0.31deg)"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Join NOW!!"
          {...getOverrideProps(overrides, "Join NOW!!")}
        ></Text>
      </Flex>
      <Button
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="5px"
        padding="8px 16px 8px 16px"
        size="default"
        isDisabled={false}
        variation="default"
        children="YouTube"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
