import './App.css';
import React from 'react';
import TabControler from './components/TabControler'

function App() {
  return (
    
    <div className="App">
      <TabControler></TabControler>
    </div> 
  );
}

export default App;
